<template>
	<div class="SwitchPage">
		<!-- 抬头标题 -->
		<div class="title-hang">
			<div class="fl-t">
				<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
			</div>
			<!-- <div class="fl-t">
				<div class="button">
				    <div class="button_word">
						远程请求结束
					</div>
				</div>
			</div> -->
			<!-- <div class="zhuangshizb fl-t"></div> -->
			<div class="zhuangshizj fl-t">{{projectData.project}}_切换页面</div>
			<!-- <div class="zhuangshiyb fl-t"></div> -->
			<div class="fr-t">
				<div class="fl-t">
					<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
				</div>
			</div>
		</div>
		<!-- 按钮 -->
		<div class="button_word">
			远程请求结束
		</div>
		<!-- 左边框框-->
		<div class="left fl-t">
			<div class="title">
				<div class="left_title">切换状态</div>
			</div>
			<div class="left_content">
				<div class="left_content_row1 flex">
					<div class="left_content_row1_stats flex align-cen">
						<div class="text">BCS控制</div>
						<div class="switc"></div>
					</div>
					<div class="left_content_row1_stats flex align-cen">
						<div class="text">DCS控制</div>
						<div class="switc1"></div>
					</div>
				</div>
				<div class="left_content_row2">
					<div class="left_content_row2_title">
						汽水系统切换
					</div>
					<div class="left_content_row2_list flex">
						<div class="list_con flex align-cen " v-for="(item, index) in list" :key="index">
							<div class="text">{{item.name}}</div>
							<div class="switc" :style="[
					{background:(infoList[item.swich] ?'#cf284b':'#0de0ac')},
					]"></div>
						</div>
					</div>
					<div class="left_content_row2_title">
						给煤系统切换
					</div>
					<div class="left_content_row2_list flex">
						<div class="list_con flex align-cen" v-for="(item, index) in list1" :key="index">
							<div class="text">{{item.name}}</div>
							<div class="switc" :style="[
					{background:(infoList[item.swich] ?'#cf284b':'#0de0ac')},
					]"></div>
						</div>
					</div>
					<div class="left_content_row2_title">
						除渣系统切换
					</div>
					<div class="left_content_row2_list flex">
						<div class="list_con flex align-cen " v-for="(item, index) in list2" :key="index">
							<div class="text">{{item.name}}</div>
							<div class="switc" :style="[
					{background:(infoList[item.swich] ?'#cf284b':'#0de0ac')},
					]"></div>
						</div>
					</div>
					<div class="left_content_row2_title">
						送风系统切换
					</div>
					<div class="left_content_row2_list flex">
						<div class="list_con flex align-cen " v-for="(item, index) in list3" :key="index">
							<div class="text">{{item.name}}</div>
							<div class="switc" :style="[
					{background:(infoList[item.swich] ?'#cf284b':'#0de0ac')},
					]"></div>
						</div>
					</div>
					<div class="left_content_row2_title">
						引风系统切换
					</div>
					<div class="left_content_row2_list flex">
						<div class="list_con flex align-cen " v-for="(item, index) in list4" :key="index">
							<div class="text">{{item.name}}</div>
							<div class="switc" :style="[
					{background:(infoList[item.swich] ?'#cf284b':'#0de0ac')},
					]"></div>
						</div>
					</div>
				</div>
			</div>

		</div>

		<!-- 中间部分 -->
		<div class="right fl-t">
			<div class="middle_up ">
				<div class="title">
					<div class="middle_up_title">控制点设定</div>
				</div>
				<div class="middle_up_content">
					<div class="middle_line flex">
						<div class="middle_bg middle_line1">
							<div>
								<div class="middle_num"
									@click="toIpt(infoList.SWSP_B1,'汽包水位','SWSP_B1',infoList.SWSP_B1_node_string)">
									{{'SWSP_B1' in infoList?  infoList.SWSP_B1 :0}}
								</div>
								<div class="middle_word">汽包水位</div>
							</div>
						</div>
						<div class="middle_bg middle_line2">
							<div>
								<div class="middle_num"
									@click="toIpt(infoList.XTPR,'主汽压力','XTPR',infoList.XTPR_node_string)">
									{{'XTPR' in infoList?  infoList.XTPR :0}}
								</div>
								<div class="middle_word">主汽压力</div>
							</div>
						</div>
						<div class="middle_bg middle_line3">
							<div class="middle_num" @click="toIpt(infoList.FYSP_B1,'炉膛负压','FYSP_B1',infoList.FYSP_B1_node_string)">
								{{'FYSP_B1' in infoList?  infoList.FYSP_B1 :0}}
							</div>
							<div class="middle_word">炉膛负压</div>
						</div>
						<div class="middle_bg middle_line1 middle_line4">
							<div class="middle_num" @click="toIpt(infoList.C_O2,'烟气含氧量','C_O2',infoList.C_O2_node_string)">
								{{'C_O2' in infoList?  infoList.C_O2 :0}}
							</div>
							<div class="middle_word">烟气含氧量</div>
						</div>
						<div class="middle_bg middle_line2 middle_line4">
							<div class="middle_num" @click="toIpt(infoList.LCSP_B1,'料层差压','LCSP_B1',infoList.LCSP_B1_node_string)">
								{{'LCSP_B1' in infoList?  infoList.LCSP_B1 :0}}
							</div>
							<div class="middle_word">料层差压</div>
						</div>
					</div>
					<div class="middle_line flex">
						<div class="middle_bg middle_line1 ">
							<div class="middle_num"
								@click="toIpt(infoList.ZQTSP_B1,'主汽温度','ZQTSP_B1',infoList.ZQTSP_B1_node_string)">
								{{'ZQTSP_B1' in infoList?  infoList.ZQTSP_B1 :0}}
							</div>
							<div class="middle_word">主汽温度</div>
						</div>
						<div class="middle_bg middle_line2">
							<div class="middle_num"
								@click="toIpt(infoList.ZQTSP11,'低过左出汽温','ZQTSP11',infoList.ZQTSP11_node_string)">
								{{'ZQTSP11' in infoList?  infoList.ZQTSP11 :0}}
							</div>
							<div class="middle_word">低过左出汽温</div>
						</div>
						<div class="middle_bg middle_line3">
							<div class="middle_num"
								@click="toIpt(infoList.ZQTSP12,'低过右出汽温','ZQTSP12',infoList.ZQTSP12_node_string)">
								{{'ZQTSP12' in infoList?  infoList.ZQTSP12 :0}}
							</div>
							<div class="middle_word">低过右出汽温</div>
						</div>
						<div class="middle_bg middle_line1">
							<div class="middle_num"
								@click="toIpt(infoList.ZQTSP_B2,'再热蒸汽温度','ZQTSP_B2',infoList.ZQTSP_B2_node_string)">
								{{'ZQTSP_B2' in infoList?  infoList.ZQTSP_B2 :0}}
							</div>
							<div class="middle_word">再热蒸汽温度</div>
						</div>
						<div class="middle_bg middle_line2">
							<div class="middle_num"
								@click="toIpt(infoList.ZQTSP21,'低再左出汽温','ZQTSP21',infoList.ZQTSP21_node_string)">
								{{'ZQTSP21' in infoList?  infoList.ZQTSP21 :0}}
							</div>
							<div class="middle_word">低再左出汽温</div>
						</div>
						<div class="middle_bg middle_line3">
							<div class="middle_num"
								@click="toIpt(infoList.ZQTSP22,'低再右出汽温','ZQTSP22',infoList.ZQTSP22_node_string)">
								{{'ZQTSP22' in infoList?  infoList.ZQTSP22 :0}}
							</div>
							<div class="middle_word">低再右出汽温</div>
						</div>
					</div>
					<div class="middle_bot_content">
						<div class="middle_bot_content-tit">快速升降负荷</div>
						<div class="middle_bot_text">
							当前负荷：
							<span @click="toCompon(2,'DQFH_SP',infoList.DQFH_SP_node_string,'DQFH_SPVV1','当前负荷设定值')"
								@dblclick="Cclick(infoList.DQFH_SP,'DQFH_SP',infoList.DQFH_SP_node_string,'DQFH_SPVV1')">
								{{'DQFH_SP' in infoList?  infoList.DQFH_SP :0}}
							</span>
						</div>
						<div class="flex" style="justify-content: space-between;">
							<div class="middle_bot_text">
								目标负荷：
								<span @click="toIpt(infoList.MBFH_SP,'目标负荷','MBFH_SP',infoList.MBFH_SP_node_string)"
									style="color:#01ffba">
									{{'MBFH_SP' in infoList?  infoList.MBFH_SP :0}}
								</span>
							</div>
							<div class="middle_bot_text">
								升降负荷时间：
								<span @click="toIpt(infoList.SJFH_T,'升降负荷时间','SJFH_T',infoList.SJFH_T_node_string)"
									style="color:#01ffba">
									{{'SJFH_T' in infoList?  infoList.SJFH_T :0}}
								</span>
							</div>
							<div class="middle_bot_btn" :style="[
					{color:(infoList.SJFHAUTO ?'#42f4ac':'#ff0a32')},
					{border:(infoList.SJFHAUTO ?'1px solid #00f0ab':'1px solid #cd0f32')}
					]" @click="toIpt(infoList.SJFHAUTO,'按钮','SJFHAUTO',infoList.SJFHAUTO_node_string)">
								{{'SJFHAUTO' in infoList?  (infoList.SJFHAUTO ? '已启动' : '已停止') :0}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="middle_bottom">
				<div class="title">
					<div class="middle_bottom_title">脱硫脱硝</div>
				</div>
				<div class="middle_bottom_content flex align-cen">
					<div class="middle_bottom_content_row1">
						<div class="list_con flex align-cen ">
							<div class="text">石灰石给料切换</div>
							<div class="switc" :style="[
					{background:(infoList.BCSTL_LN_B ?'#cf284b':'#0de0ac')},
					]"></div>
						</div>
						<div class="list_con flex align-cen ">
							<div class="text">石灰浆液切换</div>
							<div class="switc" :style="[
					{background:(infoList.BCSTL_B ?'#cf284b':'#0de0ac')},
					]"></div>
						</div>
						<div class="list_con flex align-cen ">
							<div class="text">氨水调节切换</div>
							<div class="switc" :style="[
					{background:(infoList.BCSTX_B ?'#cf284b':'#0de0ac')},
					]"></div>
						</div>
						<div class="list_con flex align-cen ">
							<div class="text">稀释水调节切换</div>
							<div class="switc" :style="[
					{background:(infoList.BCSCYS_B ?'#cf284b':'#0de0ac')},
					]"></div>
						</div>
					</div>
					<div class="middle_bottom_content_row2">
						<div class="list_con flex align-cen ">
							<div class="text">SO₂炉内控制点</div>
							<div class="text1"
								@click="toIpt(infoList.SO2LNSP,'SO₂炉内控制点','SO2LNSP',infoList.SO2LNSP_node_string)">
								{{'SO2LNSP' in infoList?  infoList.SO2LNSP :0}}
							</div>
						</div>
						<div class="list_con flex align-cen ">
							<div class="text">SO₂炉外控制点</div>
							<div class="text1" @click="toIpt(infoList.SO2SP,'SO₂炉外控制点','SO2SP',infoList.SO2SP_node_string)">
								{{'SO2SP' in infoList?  infoList.SO2SP :0}}
							</div>
						</div>
						<div class="list_con flex align-cen ">
							<div class="text">NOx控制点</div>
							<div class="text1" @click="toIpt(infoList.NOXSP,'NOx控制点','NOXSP',infoList.NOXSP_node_string)">
								{{'NOXSP' in infoList?  infoList.NOXSP :0}}
							</div>
						</div>
						<div class="list_con flex align-cen ">
							<div class="text">稀释水系数</div>
							<div class="text1" @click="toIpt(infoList.NS_CYS_K,'稀释水系数','NS_CYS_K',infoList.NS_CYS_K_node_string)">
								{{'NS_CYS_K' in infoList?  infoList.NS_CYS_K :0}}
							</div>
						</div>
					</div>
					<div class="middle_bottom_content_row3">
						<div class="middle_bot_btn">OPC通讯故障</div>
						<div class="middle_bot_st"
							:style="[{color:(infoList.ERROR_HD ?'#ff0a32':'#42f4ac')},]">
							{{'BCSYH_SHM1' in infoList?  (infoList.ERROR_HD ? '优化狗故障' :'优化狗正常') :0}}
						</div>
						<div class="middle_bot_btn" :style="[{color:(!infoList.XTEN ?'#42f4ac':'#ff0a32')},
							{border:(!infoList.XTEN ?'1px solid #00f0ab':'1px solid #cd0f32')}]">
							{{'XTEN' in infoList?  (infoList.XTEN ? '调节炉方式' : '运行炉方式') :0}}
						</div>
						<div class="middle_bot_btn middle_bot_btn1"
							@click="toIpt(infoList.BCSALL_B,'按钮','BCSALL_B',infoList.BCSALL_B_node_string)">
							{{'BCSALL_B' in infoList?  (infoList.BCSALL_B? '全部投入' : '全部切除') :0}}
						</div>
					</div>
				</div>

			</div>
		</div>

		<!-- 右边部分 -->
		<div class="right fl-t">
			<div class="right_up">
				<div class="title">
					<div class="right_up_title">系统安全</div>
				</div>
				<div class="right_up_content">
					<div class="right_up_content_list flex align-cen">
						<div class="flex align-cen" v-for="(item, index) in list5" :key="index">
							<div class="text">
								{{item.name}}
							</div>
							<div class="ipt" style="cursor: pointer;"
								@click="toIpt(infoList[item.node][item.mark],item.name,item.mark,item.node)">
								{{item.node in infoList?  infoList[item.node][item.mark] :0}}
							</div>
						</div>
					</div>
					<div class="right_up_content_list flex align-cen right_up_content_list1">
						<div class="flex align-cen" v-for="(item, index) in list6" :key="index">
							<div class="text">
								{{item.name}}
							</div>
							<div class="ipt" style="cursor: pointer;"
								@click="toIpt(infoList[item.node][item.mark],item.name,item.mark,item.node)">
								{{item.node in infoList?  infoList[item.node][item.mark] :0}}
							</div>
						</div>
					</div>
					<div class="right_up_content_list flex align-cen right_up_content_list1">
						<div class="flex align-cen" v-for="(item, index) in list7" :key="index">
							<div class="text">
								{{item.name}}
							</div>
							<div class="ipt" style="cursor: pointer;"
								@click="toIpt(infoList[item.node][item.mark],item.name,item.mark,item.node)">
								{{item.node in infoList?  infoList[item.node][item.mark] :0}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="right_bottom">
				<div class="title">
					<div class="right_bottom_title">系统监控</div>
				</div>
				<div class="right_bottom_content">
					<div class="right_bottom_circle flex">
						<div class="circle">
							<div class="right_word">DCS</div>
							<div class="right_num" @click="toCompon(2,'YFHEART_B',infoList.YFHEART_B_node_string,'YFHEART_BVV1','引风系统Bcs心跳包')"
								@dblclick="Cclick(infoList.YFHEART_B,'YFHEART_B',infoList.YFHEART_B_node_string,'YFHEART_BVV1')">
								{{'YFHEART_B' in infoList?  infoList.YFHEART_B :0}}
							</div>
						</div>
						<div class="circle circle1">
							<div class="right_word">DCS</div>
							<div class="right_num" @click="toCompon(2,'GSHEART_B',infoList.GSHEART_B_node_string,'GSHEART_BVV1','给水系统Bcs心跳包')"
								@dblclick="Cclick(infoList.GSHEART_B,'GSHEART_B',infoList.GSHEART_B_node_string,'GSHEART_BVV1')">
								{{'GSHEART_B' in infoList?  infoList.GSHEART_B :0}}
							</div>
						</div>
						<div class="circle circle1">
							<div class="right_word">DCS</div>
							<div class="right_num" @click="toCompon(2,'LZHEART_B',infoList.LZHEART_B_node_string,'LZHEART_BVV1','冷渣系统Bcs心跳包')"
								@dblclick="Cclick(infoList.LZHEART_B,'LZHEART_B',infoList.LZHEART_B_node_string,'LZHEART_BVV1')">
								{{'LZHEART_B' in infoList?  infoList.LZHEART_B :0}}
							</div>
						</div>
						<div class="circle circle1">
							<div class="right_word">DCS</div>
							<div class="right_num" @click="toCompon(2,'GMHEART_B',infoList.GMHEART_B_node_string,'GMHEART_BVV1','给煤系统Bcs心跳包')"
								@dblclick="Cclick(infoList.GMHEART_B,'GMHEART_B',infoList.GMHEART_B_node_string,'GMHEART_BVV1')">
								{{'GMHEART_B' in infoList?  infoList.GMHEART_B :0}}
							</div>
						</div>
						<div class="circle circle1">
							<div class="right_word">DCS</div>
							<div class="right_num" @click="toCompon(2,'HEART1_B',infoList.HEART1_B_node_string,'HEART1_BVV1','1#心跳数据modbus-plc')"
								@dblclick="Cclick(infoList.HEART1_B,'HEART1_B',infoList.HEART1_B_node_string,'HEART1_BVV1')">
								{{'HEART1_B' in infoList?  infoList.HEART1_B :0}}
							</div>
						</div>
						<div class="circle circle1">
							<div class="right_word">DCS</div>
							<div class="right_num" @click="toCompon(2,'GFHEART_B',infoList.GFHEART_B_node_string,'GFHEART_BVV1','鼓风系统Bcs心跳包')"
								@dblclick="Cclick(infoList.GFHEART_B,'GFHEART_B',infoList.GFHEART_B_node_string,'GFHEART_BVV1')">
								{{'GFHEART_B' in infoList?  infoList.GFHEART_B :0}}
							</div>
						</div>
						<!-- <div class="circle circle1">
							<div class="right_word">DCS</div>
							<div class="right_num"
							@click="toIpt(infoList.MCSOUREC.HEART_1A_B,'DCS','HEART_1A_B','MCSOUREC')">
							{{'' in infoList?  infoList.MCSOUREC.HEART_1A_B :0}}
						</div>
						</div> -->
					</div>
					<div class="right_bottom_circle flex">
						<div class="circle">
							<div class="right_word">BCS</div>
							<div class="right_num" @click="toCompon(2,'YFHEARTR_B',infoList.YFHEARTR_B_node_string,'YFHEARTR_BVV1','引风系统Bcs心跳返回包')"
								@dblclick="Cclick(infoList.YFHEARTR_B,'YFHEART_B',infoList.YFHEARTR_B_node_string,'YFHEARTR_BVV1')">
								{{'YFHEARTR_B' in infoList?  infoList.YFHEARTR_B :0}}
							</div>
						</div>
						<div class="circle circle1">
							<div class="right_word">BCS</div>
							<div class="right_num" @click="toCompon(2,'GSHEARTR_B',infoList.GSHEARTR_B_node_string,'GSHEARTR_BVV1','给水系统Bcs心跳返回包')"
								@dblclick="Cclick(infoList.GSHEARTR_B,'GSHEARTR_B',infoList.GSHEARTR_B_node_string,'GSHEARTR_BVV1')">
								{{'GSHEARTR_B' in infoList?  infoList.GSHEARTR_B :0}}
							</div>
						</div>
						<div class="circle circle1">
							<div class="right_word">BCS</div>
							<div class="right_num" @click="toCompon(2,'LZHEARTR_B',infoList.LZHEARTR_B_node_string,'LZHEARTR_BVV1','冷渣系统Bcs心跳包')"
								@dblclick="Cclick(infoList.LZHEARTR_B,'LZHEARTR_B',infoList.LZHEARTR_B_node_string,'LZHEARTR_BVV1')">
								{{'LZHEARTR_B' in infoList?  infoList.LZHEARTR_B :0}}
							</div>
						</div>
						<div class="circle circle1">
							<div class="right_word">BCS</div>
							<div class="right_num" @click="toCompon(2,'GMHEARTR_B',infoList.GMHEARTR_B_node_string,'GMHEARTR_BVV1','给煤系统Bcs心跳返回包')"
								@dblclick="Cclick(infoList.GMHEARTR_B,'GMHEARTR_B',infoList.GMHEARTR_B_node_string,'GMHEARTR_BVV1')">
								{{'GMHEARTR_B' in infoList?  infoList.GMHEARTR_B :0}}
							</div>
						</div>
						<div class="circle circle1">
							<div class="right_word">BCS</div>
							<div class="right_num" @click="toCompon(2,'HEARTA_B',infoList.HEARTA_B_node_string,'HEARTA_BVV1','心跳数据-送回上位')"
								@dblclick="Cclick(infoList.HEARTA_B,'HEARTA_B',infoList.HEARTA_B_node_string,'HEARTA_BVV1')">
								{{'HEARTA_B' in infoList?  infoList.HEARTA_B :0}}
							</div>
						</div>
						<div class="circle circle1">
							<div class="right_word">BCS</div>
							<div class="right_num" @click="toCompon(2,'GFHEARTR_B',infoList.GFHEARTR_B_node_string,'GFHEARTR_BVV1','鼓风系统Bcs心跳返回包')"
								@dblclick="Cclick(infoList.GFHEARTR_B,'GFHEARTR_B',infoList.GFHEARTR_B_node_string,'GFHEARTR_BVV1')">
								{{'GFHEARTR_B' in infoList?  infoList.GFHEARTR_B :0}}
							</div>
						</div>
						<!-- <div class="circle circle1">
							<div class="right_word">DCS</div>
							<div class="right_num"
							@click="toIpt(infoList.MCSOUREC.HEART_1A_B,'DCS','HEART_1A_B','MCSOUREC')">
							{{'' in infoList?  infoList.MCSOUREC.HEART_1A_B :0}}
						</div>
						</div> -->
					</div>
				</div>
			</div>
		</div>
		<inputVal ref="inputVal" @getTreeData="DataJson(arguments)"></inputVal>
		 <Historical v-if="isHshow" @sendStatus="isHshow = false" :historyname="historyname" :node="node"
			:Lkname="Lkname" :chName="chName" :infoList="infoList"></Historical>
		<Cfbcysz v-if="flag == 5 ? (isComShow = true) : (isComShow = false)" @sendStatus="isClose" :infoList="infoList">
		</Cfbcysz>
		<Cfbserve v-if="flag == 7 ? (isComShow = true) : (isComShow = false)" @sendStatus="isClose"
			:infoList="infoList"></Cfbserve>
	</div>
</template>

<script>
	import Historical from "@/components/Historicaljrl";
	import inputVal from "@/components/inputVal" //输入框组件
	import Cfbcysz from "@/views/CfbBoiler/CfbCommonSetting/index.vue"; //常用设置组件
	import Cfbserve from "@/views/CfbBoiler/CfbOnlineService/index.vue"; //客服组件
	export default {
		name: "RqSecurity",
		components: {
			Historical,
			inputVal,
			Cfbcysz,
			Cfbserve
		},
		props: {
			infoList: {
				type: Object,
				default: () => {
					return {} // 默认值
				}
			}
		},
		data: () => {
			return {
				chName: '',
				flag: 3,
				grouptime: null,
				value1: false,
				value2: true,
				isComShow: false,
				isHshow: false,
				historyname: '',
				node: '',
				Lkname: '',
				projectData: {},
				spotArr: [],
				isDisable: true,
				publicMark: 'MCSSOURCE',
				list: [{
						name: '主给水阀门',
						swich: 'BCSGS1_B'
					},
					{
						name: '副给水阀门',
						swich: 'BCSGS2_B'
					},
					{
						name: '一级甲减温',
						swich: 'BCSQW1_B'
					},
					{
						name: '再热一级甲减温',
						swich: 'BCSZR1_B'
					},
					{
						name: '一级乙减温',
						swich: 'BCSQW2_B'
					}, {
						name: '再热一级乙减温',
						swich: 'BCSZR2_B'
					},
					{
						name: '二级甲减温',
						swich: 'BCSQW3_B'
					}, {
						name: '再热二级甲减温',
						swich: 'BCSZR3_B'
					},
					{
						name: '二级乙减温',
						swich: 'BCSQW4_B'
					}, {
						name: '再热二级乙减温',
						swich: 'BCSZR4_B'
					}
				],
				list1: [{
						name: '1#给煤机',
						swich: 'BCSGM1_B'
					}, {
						name: '4#给煤机',
						swich: 'BCSGM4_B'
					}, {
						name: '2#给煤机',
						swich: 'BCSGM2_B'
					}, {
						name: '5#给煤机',
						swich: 'BCSGM5_B'
					}, {
						name: '3#给煤机',
						swich: 'BCSGM3_B'
					}, {
						name: '6#给煤机',
						swich: 'BCSGM6_B'
					}

				],
				list2: [{
						name: '1#冷渣机',
						swich: 'BCSCZ1_B'
					},
					{
						name: '2#冷渣机',
						swich: 'BCSCZ2_B'
					},
					{
						name: '3#冷渣机',
						swich: 'BCSCZ3_B'
					},
					{
						name: '4#冷渣机',
						swich: 'BCSCZ4_B'
					}
				],
				list3: [{
						name: '1#一次风变频',
						swich: 'BCSYCFBP_B'
					},
					{
						name: '2#一次风变频',
						swich: 'BCSYCFBP2_B'
					},
					{
						name: '1#一次风挡板',
						swich: 'BCSYCFDB_B'
					},
					{
						name: '2#一次风挡板',
						swich: 'BCSYCFDB2_B'
					},
					{
						name: '1#二次风变频',
						swich: 'BCSECFBP_B'
					},
					{
						name: '2#二次风变频',
						swich: 'BCSECFBP2_B'
					},
					{
						name: '1#二次风挡板',
						swich: 'BCSECFDB_B'
					},
					{
						name: '2#二次风挡板',
						swich: 'BCSECFDB2_B'
					}
				],
				list4: [{
						name: '1#引风变频',
						swich: 'BCSYFBP1_B'
					},
					{
						name: '2#引风变频',
						swich: 'BCSYFBP2_B'
					},
					{
						name: '1#引风挡板',
						swich: 'BCSYFDB1_B'
					},
					{
						name: '2#引风挡板',
						swich: 'BCSYFDB2_B'
					}
				],
				list5: [{
						name: '1#引风变频上限',
						mark: 'OUTT',
						node: 'MAN19'
					},
					{
						name: '1#引风挡板上限',
						mark: 'OUTT',
						node: 'MAN21'
					},
					{
						name: '1#引风变频下限',
						mark: 'OUTB',
						node: 'MAN19'
					},
					{
						name: '1#引风挡板下限',
						mark: 'OUTB',
						node: 'MAN21'
					},
					{
						name: '2#引风变频上限',
						mark: 'OUTT',
						node: 'MAN20'
					},
					{
						name: '2#引风挡板上限',
						mark: 'OUTT',
						node: 'MAN22'
					},
					{
						name: '2#引风变频下限',
						mark: 'OUTB',
						node: 'MAN20'
					},
					{
						name: '2#引风挡板下限',
						mark: 'OUTB',
						node: 'MAN22'
					},
				],
				list6: [{
						name: '1#二次风变频上限',
						mark: 'OUTT',
						node: 'MAN17'
					},
					{
						name: '1#二次风挡板上限',
						mark: 'OUTT',
						node: 'MAN18'
					},
					{
						name: '1#二次风变频下限',
						mark: 'OUTB',
						node: 'MAN17'
					},
					{
						name: '1#二次风挡板下限',
						mark: 'OUTB',
						node: 'MAN18'
					},
					{
						name: '2#二次风变频上限',
						mark: 'OUTT',
						node: 'MAN30'
					},
					{
						name: '2#二次风挡板上限',
						mark: 'OUTT',
						node: 'MAN26'
					},
					{
						name: '2#二次风变频下限',
						mark: 'OUTB',
						node: 'MAN30'
					},
					{
						name: '2#二次风挡板下限',
						mark: 'OUTB',
						node: 'MAN26'
					}
				],
				list7: [{
						name: '1#一次风变频上限',
						mark: 'OUTT',
						node: 'MAN15'
					},
					{
						name: '1#一次风挡板上限',
						mark: 'OUTT',
						node: 'MAN16'
					},
					{
						name: '1#一次风变频下限',
						mark: 'OUTB',
						node: 'MAN15'
					},
					{
						name: '1#一次风挡板下限',
						mark: 'OUTB',
						node: 'MAN16'
					},
					{
						name: '2#一次风变频上限',
						mark: 'OUTT',
						node: 'MAN27'
					},
					{
						name: '2#一次风挡板上限',
						mark: 'OUTT',
						node: 'MAN25'
					},
					{
						name: '2#一次风变频下限',
						mark: 'OUTB',
						node: 'MAN27'
					},
					{
						name: '2#一次风挡板下限',
						mark: 'OUTB',
						node: 'MAN25'
					},
				]
			}
		},
		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n
				},
				deep: true // 深度监听父组件传过来对象变化
			},
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
			this.$bus.$on("sendMsg", (msg) => {
				// 兄弟组件发送来的消息
				this.flag = msg;
			});
		},
		methods: {
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {

						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			DataJson(data) {
				let strNode = data[0]
				let strMark = data[1]
				this.$nextTick(() => {
					this.infoList[strNode][strMark] = data[2]
				});
			},
			//    打开历史趋势
			toCompon(key, name, name2, name3, name4) {
				this.isIndex = key
				this.historyname = name
				this.node = name2
				this.Lkname = name3
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return this.isMshow = true
						case 1:
							return this.isFshow = true
						case 2:
							return this.isHshow = true, this.chName = name4
					}
				}, 300)
			},
			isClose(val, val1) {
				switch (val) {
					case 'CfbCommonSetting':
					case (this.flag == 5):
						return this.flag = 3, this.$bus.$emit("footersendMsg", 3)
					case 'Cfbserve':
					case (this.flag == 7):
						return this.flag = 3, this.$bus.$emit("footersendMsg", 3)
				}
			},
			isClose1() {
				this.isHshow = false
			},
			toIpt(data, name, historyname, node) {
				this.$refs.inputVal.open(data, name, historyname, node)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.fl-t {
		float: left;
	}

	.fr-t {
		float: right;
	}

	.title-hang {
		height: 5vh;
		width: 96vw;
		font-family: MicrosoftYaHei;
		font-size: 2vh;
		font-weight: normal;
		font-stretch: normal;
		line-height: 2vh;
		letter-spacing: 0vh;
	}

	.zhuangshizb {
		width: 14vw;
		height: 2vh;
		background-image: url("~@/assets/images/rfl_evaluate06.png");
		background-size: 100% 100%;
		margin-left: 3.5vw;
		margin-top: 1.5vh;
	}

	.zhuangshizj {
		width: 68vw;
		height: 4vh;
		line-height: 4vh;
		margin-top: 1vh;
		text-align: center;
		// background-image: url("~@/assets/images/rfl_evaluate05.png");
		background-size: 100% 100%;
		// margin-left: 5vw;
		font-family: MicrosoftYaHei;
		font-size: 3vh;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0vh;
		color: #0ef7ff;
	}

	.zhuangshiyb {
		width: 14vw;
		height: 2vh;
		background-image: url("~@/assets/images/rfl_evaluate04.png");
		background-size: 100% 100%;
		margin-left: 5vw;
		margin-top: 1.5vh;
	}

	.button_word {
		width: 8vw;
		height: 4vh;
		margin-left: 1.5vw;
		text-align: center;
		background-color: #0f7dbd;
		color: #a0dcff;
		font-size: 1vw;
		line-height: 4vh;
	}

	.text-status {
		width: 4vw;
		height: 1.8vh;
		margin-top: 1.5vh;
		margin-left: 0vw;
	}

	.left {
		width: 28vw;
		height: 74vh;
		margin-left: 1.5vw;
		background: url("~@/assets/images/RqBoiler/bg_left.png") no-repeat 0 0;
		background-size: 100% 100%;
		border-style: solid;
		border-width: 0vw;
		border-image-source: linear-gradient(180deg,
				#000000 0%,
				#23526d 100%);
		border-image-slice: 1;
		position: relative;
		margin-top: 1.5vh;

		.title {
			height: 3vh;

			.left_title {
				color: #ffffff;
				font-size: 2vh;
				position: absolute;
				top: 0;
				left: 2vw;
			}
		}

		.left_content {
			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 3.1vh;
			letter-spacing: 0vh;
			color: #8aeaff;
			padding: 0 1vw;

			.left_content_row1 {
				justify-content: end;

				.left_content_row1_stats {
					margin-right: 1vw;
				}

				.left_content_row1_stats:last-child {
					margin-right: 0;
				}

				.switc {
					width: 4vw;
					height: 1.8vh;
					background-color: #cf284b;
					border-radius: 2px;
					margin-left: .5vw;
				}

				.switc1 {
					width: 4vw;
					height: 1.8vh;
					background-color: #0de0ac;
					border-radius: 2px;
					margin-left: .5vw;
				}
			}

			.left_content_row2 {
				.left_content_row2_title {
					color: #6acafb;
					position: relative;
					padding-bottom: 0.5vh;
				}

				.left_content_row2_title::after {
					content: '';
					position: absolute;
					height: 1px;
					width: 26vw;
					background: #09344a;
					bottom: 0;
					left: 0vw;
				}

				.left_content_row2_list {
					justify-content: space-between;
					flex-wrap: wrap;
					padding: 0 2vw;
					padding-left: 0;
					margin-left: -1vw;

					.list_con {
						width: 50%;

						.switc {
							width: 4vw;
							height: 1.8vh;
							background-color: #cf284b;
							border-radius: 2px;
							margin-left: .5vw;
						}
					}

					.list_con:nth-child(2n) {
						justify-content: end;
					}

					.list_con:nth-child(2n+1) {
						justify-content: left;

						.text {
							width: 8vw;
							text-align: right;
						}
					}
				}
			}

			.text-status {
				width: 4vw;
				height: 1.8vh;
				margin-top: 2.5vh;
			}

			.left_line1 {
				width: 6vw;
				text-align: right;
			}

			.left_line2 {
				width: 2vw;
				margin-left: 1vw;
				margin-top: -1.1vh;
			}

			.left_line3 {
				width: 7.5vw;
				text-align: right;
				margin-left: 3vw;
			}

			.left_line4 {
				width: 2vw;
				margin-left: 1vw;
				margin-top: -1.1vh;
			}
		}
	}

	.middle_up {
		width: 37vw;
		height: 52vh;
		margin-left: 1vw;
		background: url("~@/assets/images/RqBoiler/bg_right.png") no-repeat 0 0;
		background-size: 100% 100%;
		border-style: solid;
		border-width: 0vw;
		border-image-source: linear-gradient(180deg,
				#000000 0%,
				#23526d 100%);
		border-image-slice: 1;
		position: relative;

		.title {
			width: 37vw;
			height: 3vh;

			.middle_up_title {
				color: #ffffff;
				font-size: 2vh;
				position: absolute;
				top: 0;
				left: 2vw;
			}
		}

		.middle_up_content {
			width: 32vw;
			height: calc(100% - 6vh);
			margin-top: 3vh;
			margin-left: 3vw;

			.middle_line {
				height: 16vh;
				flex-wrap: wrap;

				.middle_bg {
					width: 9vw;
					height: 6vh;
					background: url("~@/assets/images/RqBoiler/middle_up.png") no-repeat 0 0;
					background-size: 100% 100%;
					border-style: solid;
					border-width: 0vw;
					border-image-source: linear-gradient(180deg,
							#000000 0%,
							#23526d 100%);
					border-image-slice: 1;
					position: relative;

					.middle_num {
						font-family: PingFang-SC-Regular;
						font-size: 1vw;
						font-weight: normal;
						font-stretch: normal;
						line-height: 3vh;
						letter-spacing: 0vh;
						color: #01ffba;
						text-align: center;
						cursor: pointer;
					}

					.middle_word {
						font-family: PingFang-SC-Regular;
						font-size: .8vw;
						font-weight: normal;
						font-stretch: normal;
						letter-spacing: 0vh;
						color: #6acafb;
						text-align: center;
					}
				}

				.middle_line2 {
					margin-left: 2vw;

					.middle_word1 {
						font-family: PingFang-SC-Regular;
						font-size: 2vh;
						font-weight: normal;
						font-stretch: normal;
						line-height: 3vh;
						letter-spacing: 0vh;
						color: #8aeaff;
						margin-left: 1vw;
						margin-top: 3vh;
					}
				}

				.middle_line3 {
					margin-left: 2vw;

					.middle_word1 {
						font-family: PingFang-SC-Regular;
						font-size: 2vh;
						font-weight: normal;
						font-stretch: normal;
						line-height: 3vh;
						letter-spacing: 0vh;
						color: #8aeaff;
						margin-left: 2vw;
						margin-top: 3vh;
					}
				}
			}

			.middle_bot_content {
				.middle_bot_content-tit {
					color: #fff;
					font-size: 2vh;
				}

				.middle_bot_text {
					font-size: .8vw;
					color: #8aeaff;
					line-height: 4.5vh;

					span {
						cursor: pointer;
					}
				}

				.middle_bot_btn {
					width: 7vw;
					height: 3vh;
					text-align: center;
					line-height: 3vh;
					cursor: pointer;
					border: solid 1px #cd0f32;
					color: #ff0a32;
				}
			}
		}
	}

	.middle_bottom {
		width: 37vw;
		height: 20vh;
		margin-left: 1vw;
		margin-top: 2vh;
		background: url("~@/assets/images/RqBoiler/bg_middle_down.png") no-repeat 0 0;
		background-size: 100% 100%;
		border-style: solid;
		border-width: 0vw;
		border-image-source: linear-gradient(180deg,
				#000000 0%,
				#23526d 100%);
		border-image-slice: 1;
		position: relative;

		.title {
			width: 37vw;
			height: 3vh;

			.middle_bottom_title {
				color: #ffffff;
				font-size: 2vh;
				position: absolute;
				top: 0;
				left: 3vw;
			}
		}

		.middle_bottom_content {
			justify-content: space-between;
			height: calc(100% - 3vh);
			padding: 0 3vw;
			padding-left: 2vw;

			.text {
				font-size: 1vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 3.1vh;
				color: #8aeaff;
				width: 8vw;
				text-align: right;
			}

			.text1 {
				color: #00ffb4;
				font-size: 1vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 3.1vh;
				margin-left: 1vw;
				cursor: pointer;
			}

			.switc {
				width: 4vw;
				height: 1.8vh;

				border-radius: 2px;
				margin-left: .5vw;
			}

			.middle_bot_btn {
				width: 7vw;
				height: 3vh;
				text-align: center;
				line-height: 3vh;
				cursor: pointer;
				border: solid 1px #cd0f32;
				color: #ff0a32;
				margin-bottom: 1vh;
			}

			.middle_bot_btn1 {
				border: solid 1px #0d62ff;
				color: #0d62ff;
			}

			.middle_bot_st {
				text-align: center;
			}
		}

	}

	.right_up {
		width: 28vw;
		height: 52vh;
		margin-left: 1vw;
		background: url("~@/assets/images/RqBoiler/bg_middle.png") no-repeat 0 0;
		background-size: 100% 100%;
		border-style: solid;
		border-width: 0vw;
		border-image-source: linear-gradient(180deg,
				#000000 0%,
				#23526d 100%);
		border-image-slice: 1;
		position: relative;

		.title {
			width: 28vw;
			height: 3vh;

			.right_up_title {
				color: #ffffff;
				font-size: 2vh;
				position: absolute;
				top: 0;
				left: 3vw;
			}
		}

		.right_up_content {
			width: 26vw;
			height: 42vh;
			margin-top: 1.5vh;
			line-height: 3.5vh;

			.right_up_content_list {
				justify-content: space-between;
				flex-wrap: wrap;

				.flex {
					width: 50%;
					color: #8aeaff;
					font-size: 1vw;
					text-align: right;

					.text {
						width: 9vw;
						text-align: right;
					}

					.ipt {
						padding-left: 1vw;
						color: #01ffba;
						width: 3vw;
						text-align: right;
					}
				}

				.flex:nth-child(2n) {
					justify-content: end;
				}
			}

			.right_up_content_list1 {
				margin-top: 2vh;
			}

			.right_up_line1 {
				width: 6vw;
				font-family: PingFang-SC-Regular;
				font-size: 2vh;
				font-weight: normal;
				font-stretch: normal;
				letter-spacing: 0vh;
				color: #8aeaff;
			}

			.right_up_line2 {
				width: 2vw;
				margin-left: 1vw;
			}

			.right_up_line3 {
				width: 4vw;
				margin-left: 3vw;
				font-family: PingFang-SC-Regular;
				font-size: 1vw;
				font-weight: normal;
				font-stretch: normal;
				letter-spacing: 0vw;
				color: #8aeaff;
				text-align: center;

			}

			.right_up_line4 {
				width: 4vw;
				margin-left: 3vw;
				font-family: PingFang-SC-Regular;
				font-size: 1vw;
				font-weight: normal;
				font-stretch: normal;
				letter-spacing: 0vw;
				color: #8aeaff;
				text-align: center;
			}
		}

	}

	.right_bottom {
		width: 28vw;
		height: 20vh;
		margin-left: 1vw;
		margin-top: 2vh;
		background: url("~@/assets/images/RqBoiler/bg_right_down.png") no-repeat 0 0;
		background-size: 100% 100%;
		border-style: solid;
		border-width: 0vw;
		border-image-source: linear-gradient(180deg,
				#000000 0%,
				#23526d 100%);
		border-image-slice: 1;
		position: relative;

		.title {
			width: 28vw;
			height: 3vh;

			.right_bottom_title {
				color: #ffffff;
				font-size: 2vh;
				position: absolute;
				top: 0;
				left: 3vw;
			}
		}

		.right_bottom_content {

			.right_bottom_circle {

				// margin-left: 3vw;
				// margin-top: 4vh;
				.circle {
					width: 4vw;
					height: 6vh;
					background: url("~@/assets/images/RqBoiler/right_down.png") no-repeat 0 0;
					background-size: 100% 100%;
					font-family: PingFang-SC-Regular;
					font-size: .8vw;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2vh;
					letter-spacing: 0vh;
					text-align: center;
					margin-left: .6vw;
					margin-top: 2vh;

					.right_word {
						margin-top: 1vh;
						color: #6acafb;

					}

					.right_num {
						color: #ffffff;
						cursor: pointer;
					}
				}
			}

			.right_bottom_normal {
				width: 8vw;
				height: 4vh;
				background: url("~@/assets/images/RqBoiler/normal.png") no-repeat 0 0;
				background-size: 100% 100%;
				margin-left: 3vw;
				margin-top: 6vh;

				.normal {
					font-family: FZLTZHK--GBK1-0;
					font-size: 2vh;
					font-weight: normal;
					font-stretch: normal;
					line-height: 4vh;
					letter-spacing: 0vh;
					color: #45ffb2;
					text-align: center;
				}
			}
		}
	}
</style>
